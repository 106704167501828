.wallet-dropdown-overlay {
  background: $black;
  opacity: 0.4;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -9999px;
  opacity: 0;
  transition: $fadeOff;

  .active & {
    left: 0;
    opacity: 0.4;
    transition: $fadeOn;
  }
}

.wallet-dropdown {
  position: relative;
  z-index: 20;
}

.wallet-dropdown-button {
  border: 1px solid $white;
  line-height: 22px;
  padding: 0 10px;
  font-size: 12px;
  color: $white;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 8px;
    transform: rotate(180deg);
    transition: 0.2s transform;
  }

  .active & svg {
    transform: none;
  }
}

.wallet-dropdown-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  width: 190px;
  border-radius: 15px;
  padding: 0 16px;
  margin-top: 12px;
  right: 9999px;
  opacity: 0;
  transition: $fadeOffR;

  .active & {
    transition: $fadeOnR;
    opacity: 1;
    right: -10px;
  }
}

.wallet-dropdown-wallet-disconnect,
.wallet-dropdown-wallet-link {
  padding: 16px 0;
}

.wallet-dropdown-wallet-link {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border;
}

.wallet-dropdown-wallet-link-indicator {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2bff88;
  margin: 0 12px;
}

.wallet-dropdown-wallet-link-address {
  flex: 1;
  font-size: 12px;
  text-transform: uppercase;
}

.wallet-dropdown-wallet-disconnect {
  text-transform: uppercase;
  font-size: 12px;
  color: #ff6a58;
}
