.success-body {
  font-size: 13px;

  a {
    text-decoration: none;
    color: $blue;
  }

  a:hover {
    text-decoration: underline;
  }
}
