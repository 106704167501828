.nft-claim {
  background: $grayBg;
  border-radius: 15px;
  padding: 16px;
  display: flex;
  align-items: center;
}

.nft-claim-title {
  flex: 1;
  margin-right: 16px;
}
