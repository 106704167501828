.footer {
  color: $footerGray;
  text-align: center;
  padding: 24px 0;

  svg {
    margin: 0 auto;
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 10px;
  }
}
