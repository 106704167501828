.card {
  background: $white;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.card-loading {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
}

.card-padding {
  padding: 24px;
}

.card-footer {
  background: $grayBg;
  border-radius: 0 0 30px 30px;
  position: relative;
  z-index: 2;
}

.card-back {
  display: flex;
  align-items: center;
  font-family: $font2;
  font-weight: bold;
  text-transform: uppercase;
  color: $black;
  margin-bottom: 24px;
  font-size: 12px;
}

.card-back-icon {
  width: 24px;
  height: 24px;
  background: $black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $white;
  margin-right: 12px;

  svg {
    width: 5px;
    transform: translateX(-1px);
  }
}
