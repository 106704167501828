.badge {
  text-transform: uppercase;
  background: $black;
  color: $green;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  line-height: 24px;
  padding: 0 10px;
}
