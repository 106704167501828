@font-face {
  font-family: 'ABC Expanded';
  src: url('../../fonts/abc-expanded-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Mono';
  src: url('../../fonts/abc-mono-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Mono';
  src: url('../../fonts/abc-mono-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
